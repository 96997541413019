

.sNS {
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 12px 36px;
    box-shadow: -10px 0px 10px 1px rgba(0, 0, 0, 0.4);
}

.day-theme .sNS {
    background-color: #F1593A;

    /* will be "on top", if browser supports it */
    background-image: linear-gradient(#F1593A, #EC2181);
}

.dark-theme .sNS {
    background-color: #202020;
}

.sNS .nav-logo {
    width: 150px;
    margin-bottom: 24px;
}


.day-theme  .sidenav-header {
    margin-top: 12px;
    margin-bottom: 36px;
    font-size: 36px;
    font-weight: 700;
    color: white;
    margin-left: auto;
    margin-right: auto;
}

.dark-theme  .sidenav-header {
    margin-top: 12px;
    margin-bottom: 36px;
    font-size: 36px;
    font-weight: 700;
    color: #F1593A;
    margin-left: auto;
    margin-right: auto;
}


.sNS h6 {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Antonio', sans-serif;
    margin: 12px 0px;
    cursor: pointer;
    z-index: 999;
}

.d-enter {
    opacity: 0.01;
}

.d-enter.d-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

.d-leave {
    opacity: 1;
}

.d-leave.d-leave-active {
    opacity: 0.01;
    transition: opacity 100ms ease-out;
}