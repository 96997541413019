.down-wrapper {
    min-height: 60vh;
    padding: 120px 0px;
    background-image: url('../../../assets/bg.jpg');
    background-size: cover;
    position: relative;
}

.dark-theme .bg-down {
  background-color: #202020;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  opacity: 0.6;
  z-index: 1;
}

.day-theme .bg-down {
  background-color: #F1593A;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(#F1593A, #EC2181);
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    opacity: 0.4;
    z-index: 1;
  }

.down-wrapper h1 {
    font-weight: 700;
    z-index: 2;
    font-family: 'Roboto', sans-serif;
}

.day-theme .down-wrapper h1 {
  color: white;
}

.dark-theme .down-wrapper h1 {
  color: white;
}

.down-card {    
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 6px;
  padding: 30px;
  z-index: 2;
}

.day-theme .down-card {
  background-color: white;
}

.dark-theme .down-card {
  background-color: #202020;
}

.down-wrapper .fa {
  color: #F1593A;
}

.day-theme .down-wrapper h4 {
  color: black;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}

.dark-theme .down-wrapper h4 {
  color: white;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}

.day-theme .down-wrapper p{
  color: rgb(126, 125, 125);
  text-align: center;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

.dark-theme .down-wrapper p {
  color: rgb(172, 172, 172);
  text-align: center;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}


.down-wrapper .btn-download {
  font-size: 16px;
  font-weight: 600;
  border-radius: 1000px;
  margin-right: 12px;
  padding: 12px 36px 12px 36px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: ease all 0.5s;
  color: white;
  background-color: #F1593A;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(#F1593A, #EC2181);
  font-family: 'Roboto', sans-serif;
}

.down-wrapper .btn-download:hover {
  color: #F1593A;
  background-color: white;
  background-image: none;
}


@media only screen and (max-width: 992px) {
  .down-wrapper {
    background-position: center;
}
}