.working-wrapper {
    min-height: 40vh;
    padding: 60px 0px;
}

.dark-theme .working-wrapper {
  background-color: #202020;
}

.day-theme .working-wrapper {
    background-color: white;
  }

.working-wrapper h1 {
    font-weight: 700;
    margin-bottom: 30px;
    font-family: 'Roboto', sans-serif;
}

.working-wrapper h4  {
  margin-top: -20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.day-theme .working-wrapper h1, .day-theme .working-wrapper h4 {
    color: black;
}

.dark-theme .working-wrapper h1, .dark-theme .working-wrapper h4 {
    color: white;
}

.day-theme .working-wrapper p{
    color: rgb(126, 125, 125);
    text-align: center;
    font-family: 'Rubik', sans-serif;
}

.dark-theme .working-wrapper p {
    color: rgb(172, 172, 172);
    text-align: center;
    font-family: 'Rubik', sans-serif;
}

.working-card {
    border-radius: 8px;
    padding: 12px 30px;
}

.display-none-lg {
  display: none !important;
}

.display-none-sm {
  display: flex !important;
}

.work-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 24px;
  border-radius: 1000px;  
  background-color: #F1593A;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(#F1593A, #EC2181);
  color: white;
  width: fit-content;
  z-index: 1;
  font-family: 'Roboto', sans-serif;
}

.working-wrapper .line1 {
  position: absolute;
  z-index: 0;
  width: 50%;
  left: -50%;
  height: 2px;  
  background-color: #F1593A;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(#F1593A, #EC2181);
}

.working-wrapper .line2 {
  position: absolute;
  z-index: 0;
  width: 50%;
  left: 100%;
  height: 2px;  
  background-color: #F1593A;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(#F1593A, #EC2181);
}

@media only screen and (max-width: 992px) {
  .display-none-sm {
    display: none !important;
    width: 0px;
    height: 0px;
    overflow: hidden;
  }

  .display-none-lg {
    display: flex !important;
  }
}