.footer-wrapper {
    min-height: 40vh;
    padding: 60px 0px 0px 0px;  
    background-color: #F1593A;
    /* will be "on top", if browser supports it */
    background-image: linear-gradient(#F1593A, #EC2181);
    color: white;
}

.footer-wrapper h4 {
  font-weight: 700;
  font-family: 'Antonio', sans-serif;
}

.footer-wrapper p {
  color: rgb(230, 230, 230);
  font-family: 'Rubik', sans-serif;
}


.footer-wrapper .outline {
  border: 1px solid white;
  border-radius: 1000px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  margin-right: 12px;
}

 .footer-wrapper h5 {
   font-size: 16px;
  color: white;
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
}

 .footer-wrapper h6 {
   font-size: 14px;
  color:rgb(230, 230, 230);
  font-family: 'Rubik', sans-serif;
}
