.app-wrapper {
    min-height: 40vh;
    padding: 60px 0px;
}

@font-face{
  font-family: 'Title-Lutschine';
  src: local('LutschineFont'), url(../../../assets/Fonts/lutschine-font-1707819121-0/Lutschine-BoldConx1.otf);
}

.app-wrapper-title {
  font-family: Title-Lutschine !important;   
}

.dark-theme .app-wrapper {
  background-color: #202020;
}

.day-theme .app-wrapper {
  background-color: rgb(238, 238, 238);
  }

.app-wrapper h1 {
    font-weight: 700;
    font-family: 'Antonio', sans-serif;
}

.day-theme .app-wrapper h1 {
  color: black;
}

.dark-theme .app-wrapper h1 {
  color: white;
}

.app-card {
    -webkit-animation: slideIn2 0.5s forwards;
    -moz-animation: slideIn2 0.5s forwards;
    animation: slideIn2 0.5s forwards;
}

.app-wrapper .dash {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    margin: 0px 6px;
    cursor: pointer;
}

.app-wrapper .active {
  background-color: #F1593A;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(#F1593A, #EC2181);
}

.app-wrapper .inactive {
    background-color: rgb(204, 204, 204);
}

@-webkit-keyframes slideIn2 {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @-moz-keyframes slideIn2 {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideIn2 {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
