.toggleIcons {
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 12px 20px 14px 20px;
    right: 36px;
    bottom: 20%;
    border-radius: 1000px;
    color: white;
    font-size: 20px;
    cursor: pointer;
    z-index: 999;
}

.dark-toggle {
    background-color: #202020;
}

.day-toggle { background-color: #F1593A;

    /* will be "on top", if browser supports it */
    background-image: linear-gradient(#F1593A, #EC2181);
}

@media only screen and (max-width: 992) {
    .toggleIcons {
        right: 18px;
        bottom: 18px;
    }
}