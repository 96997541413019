.pricing-wrapper {
    min-height: 40vh;
    padding: 60px 0px;
}

@font-face{
  font-family: 'Title-Lutschine';
  src: local('LutschineFont'), url(../../../assets/Fonts/lutschine-font-1707819121-0/Lutschine-BoldConx1.otf);
} 


.pricing-wrapper-title  {
  font-family: Title-Lutschine !important;   
}

.dark-theme .pricing-wrapper {
  background-color: #202020;
}

.day-theme .pricing-wrapper {
    background-color: rgb(238, 238, 238);
  }

.pricing-wrapper h1 {
    font-weight: 700;
    font-family: 'Antonio', sans-serif;
}

.day-theme .pricing-wrapper h1 {
  color: black;
}

.dark-theme .pricing-wrapper h1 {
  color: white;
}

.pricing-card {    
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 12px;
  padding: 12px 0px;
}

.pricing-card h3{
  font-family: 'Title-Lutschine' !important;
}

 .pricing-card h4{
  font-family: 'Title-Lutschine' !important;
}

.pricing-card .pricing-month{
  font-family: 'Title-Lutschine' !important;
}

.day-theme .pricing-card {
  background-color: white;
}

.dark-theme .pricing-card {
  background-color: #202020;
}

.day-theme .pricing-wrapper h4 {
  color: black;
  font-weight: 700;
  padding: 6px 30px;
  font-family: 'Rubik', sans-serif;
}

.dark-theme .pricing-wrapper h4 {
  color: white;
  font-weight: 700;
  padding: 6px 30px;
  font-family: 'Rubik', sans-serif;
}

.day-theme .pricing-wrapper .line {
  background-color:rgb(212, 212, 212);
  width: 100%;
  height: 1px;
}

.dark-theme .pricing-wrapper .line {
  background-color:rgb(126, 126, 126);
  width: 100%;
  height: 1px;
}

.day-theme .pricing-wrapper .line2 {
  background-color:rgb(212, 212, 212);
  width: 100%;
  height: 1px;
  padding: 0px 30px;
}

.dark-theme .pricing-wrapper .line2 {
  background-color:rgb(126, 126, 126);
  width: 100%;
  height: 1px;
  padding: 0px 30px;
}

.day-theme .pricing-wrapper p{
  color: rgb(126, 125, 125);
  text-align: center;
  padding: 0px 30px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
}

.dark-theme .pricing-wrapper p {
  color: rgb(155, 155, 155);
  text-align: center;
  padding: 0px 30px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
}

.day-theme .pricing-wrapper h6{
  color: rgb(133, 133, 133);
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

.dark-theme .pricing-wrapper h6 {
  color: rgb(167, 167, 167);
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

.pricing-wrapper .btn-download {
  font-size: 16px;
  font-weight: 600;
  border-radius: 1000px;
  margin-right: 12px;
  padding: 12px 36px 12px 36px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: ease all 0.5s;
  color: white;
  background-color: #F1593A;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(#F1593A, #EC2181);
  margin-bottom: 12px;
  font-family: 'Rubik', sans-serif;
}

.pricing-wrapper .btn-download:hover {
  background-color: white;
  background-image: none;
  color: #F1593A;
}


.pricing-wrapper .fa {
  color: #F1593A;
  margin-right: 12px;
  margin-top: -6px;
}

.pricing-wrapper h3 {
  color: #F1593A;
  font-size: 60px;
  font-weight: 700;
  font-family: 'Rubik', sans-serif;
}

@-webkit-keyframes slideIn2 {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @-moz-keyframes slideIn2 {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideIn2 {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
