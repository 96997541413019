.home-wrapper {
    min-height: 100vh;
    color: white;
    position: relative;
}

.day-theme .home-wrapper {
    background-color: #F1593A;

    /* will be "on top", if browser supports it */
    background-image: linear-gradient(#F1593A, #EC2181);
    border-end-end-radius: 20%;
}


.dark-theme .home-wrapper {
    background-color: #202020;
}

.home-wrapper .home-sub-wrapper {
    min-height: 80vh;
    padding: 60px 0px;
}

.day-theme .home-wrapper .home-sub-wrapper h2 {
    font-size: 45px;
    font-weight: 700;
    font-family: 'Antonio', sans-serif;
}

.dark-theme .home-wrapper .home-sub-wrapper h2 {
    font-size: 45px;
    font-weight: 700;
    color: white;
    font-family: 'Antonio', sans-serif;
}

.home-wrapper .home-sub-wrapper p {
    font-size: 18px;
    font-weight: 400;
    margin-top: 24px;
    
}

.home-wrapper .home-sub-wrapper .btn-download {
    font-size: 16px;
    font-weight: 600;
    border-radius: 1000px;
    margin-right: 12px;
    padding: 12px 36px 12px 36px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: ease all 0.5s;
    font-family: 'Rubik', sans-serif;
    background-color: #F1593A;
    background-image: linear-gradient(#F1593A, #EC2181);    
    color: white; 
}

.home-wrapper .home-sub-wrapper .btn-download:hover {
    color: #F1593A !important;
    background-color: white !important;
    background-image: none !important; 
}

.home-wrapper .home-sub-wrapper .btn-play {
    font-size: 24px;
    font-weight: 600;
    color: #F1593A;
    background-color: white;
    border-radius: 1000px;
    margin-left: 12px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: ease all 0.5s;
    animation: pulse_01 1s ease infinite;
}

.home-img-wrapper {
    position: relative;
}

.home-img img {
    max-width: 250px;
    width: 100%;
    animation: bounce_01 3s ease infinite;
}

@font-face{
    font-family: 'Title-Lutschine';
    src: local('LutschineFont'), url(../../../assets/Fonts/lutschine-font-1707819121-0/Lutschine-BoldConx1.otf);
}



.home-sub-wrapper .title {
    font-family: Title-Lutschine !important;   
}

.home-sub-wrapper .body-text {
    font-family: 'Rubik', sans-serif !important;
}



.home-img {
    position: relative;
}

.home-img .small-circle {
    position: absolute;
    height: 60px;
    width: 60px;
    left: -45px;
    top: 75px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    transform-origin: 170px 170px;
    animation: spin_01 10s linear infinite;
    z-index: 1;
}

.home-img .circle {
    height: 400px;
    width: 400px;
    position: absolute;
    z-index: 1;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.effect-wrap .effect {
    position: absolute;
    z-index: 0;
} 

.effect-wrap .effect-1 {
    left: 20%;
    top: 20%;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    animation: spin_01 5s linear infinite ;
} 

.effect-wrap .effect-2 {
    right: 5%;
    top: 27%;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.5);
    animation: spin_01 7s linear infinite ;
} 

.effect-wrap .effect-3 {
    left: 5%;
    bottom: 20%;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.5);
    animation: bounce_01 3s linear infinite ;
} 


@keyframes spin_01 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes bounce_01 {
    0%, 100% {
        transform: translateY(-30px);
    }

    50% {
        transform: translateY(0px);
    }
}

@keyframes pulse_01 {
    0%{
        transform: scale(0.94);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 12px rgba(255, 255, 255, 0);
    }

    100% {
        transform: scale(0.94);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@media only screen and (max-width:992px) {
    .effect-wrap .effect-1 {
        left: 20%;
        top: 10%;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.5);
        animation: spin_01 5s linear infinite ;
    } 
}

@media only screen and (max-width: 576px) {
    .home-img-wrapper {
        position: relative;
    }
    
    .home-img img {
        max-width: 180px;
        width: 100%;
    }
    
    .home-img {
        position: relative;
    }
    
    .home-img .small-circle {
        position: absolute;
        height: 60px;
        width: 60px;
        left: -45px;
        top: 50px;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 50%;
        transform-origin: 130px 130px;
        animation: spin_01 10s linear infinite;
    }
    
    .home-img .circle {
        height: 300px;
        width: 300px;
        position: absolute;
        z-index: 1;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}