.navbar {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center;
    padding: 12px 30px;
    height: 100px;
    color: white;
    overflow: hidden;
}



.navbar .nav-logo {
    width: 160px;
}

.day-theme .navbar h1 {
    font-weight: 700;
    font-family: 'Antonio', sans-serif;
}

.dark-theme .navbar h1 {
    font-weight: 700;
    font-family: 'Antonio', sans-serif;
    color: #F1593A;

}

.day-theme .navbar .fa-bars {
    color: white;
}

.dark-theme .navbar .fa-bars {
    color: #F1593A;

}

.navbar h6 {
    margin-left: 18px;
    margin-right: 18px;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 12px;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    margin-top: 16px;
    transition: ease-in-out border-bottom 0.5s;
    font-family: 'Antonio', sans-serif;
    letter-spacing: 1px;
}

.navbar h6:hover {
    border-bottom: 1px solid white;
}

.display-lg-none {
    display: none !important;
  width: 0px;
        height: 0px;
        overflow: hidden;
}

.display-sm-none {
    display: flex !important;
}

@media only screen and (max-width: 992px) {
    .display-sm-none {
        display: none !important;
        width: 0px;
        height: 0px;
        overflow: hidden;
    }

    .display-lg-none {
        display: flex !important;  
        width: auto;
        height: auto;
        overflow: hidden;
    }
}