.team-wrapper {
    min-height: 40vh;
    padding: 60px 0px;
}

.dark-theme .team-wrapper {
  background-color: #202020;
}

.day-theme .team-wrapper {
    background-color: white;
  }

  .team-wrapper h1 {
    font-weight: 700;
    font-family: 'Antonio', sans-serif;
}

.team-wrapper h5 {
  font-weight: 700;
  font-family: 'Rubik', sans-serif;
}

.team-wrapper h6 {
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

.day-theme .team-wrapper h1, .day-theme .team-wrapper h5 , .day-theme .team-wrapper h6 {
  color: gray;
}

.dark-theme .team-wrapper h1, .dark-theme .team-wrapper h5, .dark-theme .team-wrapper h6 {
  color: rgb(163, 163, 163);
}

.day-theme .team-wrapper h1, .day-theme .team-wrapper h5 {
    color: black;
}

.dark-theme .team-wrapper h1, .dark-theme .team-wrapper h5{
    color: white;
}

.team-card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 6px;
    padding: 30px;
    -webkit-animation: slideIn 0.5s forwards;
    -moz-animation: slideIn 0.5s forwards;
    animation: slideIn 0.5s forwards;
}


.team-wrapper .dash {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    margin: 0px 6px;
    cursor: pointer;
}

.team-wrapper .active {
  background-color: #F1593A;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(#F1593A, #EC2181);
}

.team-wrapper .inactive {
    background-color: rgb(204, 204, 204);
}

@-webkit-keyframes slideIn {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @-moz-keyframes slideIn {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideIn {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
