.home-mission-wrapper {
    min-height: 10vh;
    padding: 60px 0px 0px 0px;
}

.dark-theme .home-mission-wrapper {
  background-color: #202020;
}

.day-theme .home-mission-wrapper {
    background-color: white;
  }

.home-mission-wrapper h1 {
    font-weight: 700;
    font-family: 'Antonio', sans-serif;
}

.home-mission-wrapper h4 {
  font-weight: 700;
  font-family: 'Rubik', sans-serif;
}

.day-theme .home-mission-wrapper h1, .day-theme .home-mission-wrapper h4 {
    color: black;
}

.dark-theme .home-mission-wrapper h1, .dark-theme .home-mission-wrapper h4 {
    color: white;
}

.day-theme .home-mission-wrapper p{
    color: black;
    text-align: center;
    font-family: 'Rubik', sans-serif;
    max-width: 60%;
}

.dark-theme .home-mission-wrapper p {
    color: rgb(172, 172, 172);
    text-align: center;
    font-family: 'Rubik', sans-serif;
    max-width: 60%;
}

.lets-build-card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 6px;
    padding: 30px 60px;
    -webkit-animation: slideIn 0.5s forwards;
    -moz-animation: slideIn 0.5s forwards;
    animation: slideIn 0.5s forwards;
}

.home-mission-wrapper .wrapper-card {
  position: relative;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-mission-wrapper .fa {
    color:#F1593A;
    z-index: 1;
    transition: all 0.2s ease-in-out;
}

.home-mission-wrapper .btn-download {
  font-size: 16px;
  font-weight: 600;
  border-radius: 1000px;
  margin-right: 12px;
  padding: 12px 36px 12px 36px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: ease all 0.5s;
  color: white;
  background-color: #F1593A;
  background-image: linear-gradient(#F1593A, #EC2181);
  margin-bottom: 12px;
  font-family: 'Rubik', sans-serif;
}

.home-mission-wrapper .btn-download:hover {
  background-color: white;
  background-image: none;
  color: #F1593A;
}


.home-mission-wrapper .wrapper-card::before {
  content: '';
  position: absolute;
  left: 100%;
  width: 100%;
  height: 100%;
  top: 0%;
  z-index: 0;
  background-color:transparent;
  transition: all 0.2s ease-in-out;
}

.home-mission-wrapper .lets-build-card:hover .wrapper-card::before {
  content: '';
  position: absolute;
  left: 0%;
  width: 100%;
  height: 100%;
  top: 0%; background-color: #F1593A;
  background-image: linear-gradient(#F1593A, #EC2181);
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
}

.home-mission-wrapper .lets-build-card:hover .fa {
  color: white;
}

.home-mission-wrapper .dash {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    margin: 0px 6px;
    cursor: pointer;
}

.home-mission-wrapper .active {
  background-color: #F1593A;
  background-image: linear-gradient(#F1593A, #EC2181);
}

.home-mission-wrapper .inactive {
    background-color: rgb(204, 204, 204);
}


@media only screen and (max-width: 992px) {
  .day-theme .home-mission-wrapper p {
    max-width: 90%;
}

.dark-theme .home-mission-wrapper p {
  max-width: 90%;
}
}

@-webkit-keyframes slideIn {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @-moz-keyframes slideIn {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideIn {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
