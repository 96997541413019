@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@400;500;600;700&family=Rubik&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 350px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.dark-bg {
  background-color: #202020;
}

.day-bg{
  background-color: #F1593A;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(#F1593A, #EC2181);
}

.dark-color {
  color: #202020;
}

.day-color {
  color:#F1593A;
}

.modalDiv {
  margin: 0 auto;
  display: table;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.modal-toast {
  position: fixed;
  right: 36px;
  top: 36px;
}