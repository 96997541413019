.contact-wrapper {
    min-height: 40vh;
    padding: 60px 0px;
}

.dark-theme .contact-wrapper {
  background-color: #202020;
}

.day-theme .contact-wrapper {
    background-color: rgb(238, 238, 238);
  }

.contact-wrapper h1 {
    font-weight: 700;
    font-family: 'Antonio', sans-serif;
    text-align: center;
}

.day-theme .contact-wrapper h1 {
  color: black;
}

.dark-theme .contact-wrapper h1 {
  color: white;
}

.contact-wrapper .outline {
  border: 1px solid #F1593A;
  border-radius: 1000px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #F1593A;
  font-size: 18px;
  margin-right: 12px;
}

.day-theme .contact-wrapper h5,.day-theme .contact-wrapper h4 {
  color: black;
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
}

.dark-theme .contact-wrapper h5,.dark-theme .contact-wrapper h4 {
  color: white;
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
}

.day-theme .contact-wrapper h6 {
  color:rgb(126, 126, 126);
  font-family: 'Rubik', sans-serif;
}

.dark-theme .contact-wrapper h6 {
  color: rgb(212, 212, 212);
  font-family: 'Rubik', sans-serif;
}

.day-theme .contact-wrapper p {
  color:black;
  font-family: 'Rubik', sans-serif;
  text-align: center;
}

.dark-theme .contact-wrapper p {
  color: white;
  font-family: 'Rubik', sans-serif;
  text-align: center;
}

.input-text {
  padding: 12px 18px;
  font-weight: 500;
  border: none;
  outline: none;
  box-shadow: none;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  border-radius: 4px;
  resize: none;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  border: 2px solid transparent;
  transition: ease-in-out border 0.5s;
}

.input-text:hover , .input-text:active, .input-text:focus {
  border: 2px solid #F1593A;
}

.day-theme .contact-wrapper .input-text{
  color:black;
  background-color: white;
}

.dark-theme .contact-wrapper .input-text {
  background-color: #202020;
  color: white;
}

.day-theme .contact-wrapper .input-text::placeholder {
  color:rgb(88, 88, 88);
}

.dark-theme .contact-wrapper .input-text::placeholder {
  color: rgb(161, 161, 161);
}

.contact-wrapper .btn-submit {
  font-size: 16px;
  font-weight: 600;
  border-radius: 1000px;
  margin-right: 12px;
  padding: 12px 36px 12px 36px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: ease all 0.5s;
  color: white;  
  background-color: #F1593A;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(#F1593A, #EC2181);
  margin-bottom: 12px;
  font-family: 'Rubik', sans-serif;
}

.contact-wrapper .btn-submit:hover {
  background-color: white;
  background-image: none;
  color: #F1593A;
}